/* eslint-disable no-alert */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../Components/Loader';
import { bindActionCreators } from 'redux';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { DropdownList, DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import Map from '../../Components/ZoneMap';
import ModalTitle from '../../Components/ModalTitle';
import Popup from 'reactjs-popup';
import { getFeatures } from '../../state/zones';
import { getRidesInZone } from '../../state/zone_rides';
import { setSelectedZone } from '../../state/selected_zone';
import { getActiveZones } from '../../util/helper';
import RideDetails from '../../Components/RideDetails';

import moment from 'moment-timezone';
import { Modal } from 'antd';
import 'antd/dist/antd.css';

import './index.scss';

moment.locale('en');
momentLocalizer();

class RideMaps extends Component {
  constructor(props) {
    super(props);
    const selectedZone = this.props.selected_zone
      ? this.props.zones.find((zone) => zone.id === this.props.selected_zone)
      : undefined;
    this.state = {
      activeZones: getActiveZones(this.props.zones, this.props.admin.user_default_zones),
      error: null,
      loaded: true,
      mapCenter: {},
      mapEndPlaces: [],
      mapPlaces: [],
      mapRideRoutes: [],
      mapRides: [],
      mapStartPlaces: [],
      mapZoneBoundary: [],
      open: false,
      rides_end: undefined,
      rides_start: undefined,
      selected_ride_filter: 'all_rides',
      selectedInvoiceRide: null,
      selectedRide: null,
      selectedRideClickLocation: null,
      selectedZone,
      show_end: true,
      show_middle: true,
      show_start: true,
      showDateSelect: Boolean(selectedZone),
      showParkingSpots: false,
      showPartialRefund: false,
      showRestrictedAreas: false,
      showRideMap: false,
      showRideSegmentFilters: Boolean(selectedZone),
      showZoneSelect: true,
      zones: [],
    };
    selectedZone && this.handleZoneChange(selectedZone, 'zone_state');
  }

  closePopup = () => {
    this.setState({ open: false, selectedRide: '' });
  };

  openMapClick = () => {
    this.setState({
      showMap: false,
    });
    this.setState({
      selectedRestrictedArea: [],
      showMap: true,
    });
  };

  showModal = async () => {
    await this.props.actions.getAllZones();
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleShowRestrictedAreasChange = async (event) => {
    this.setState({
      loaded: false,
      showMap: false,
    });
    const val = event.target.checked;
    const name = 'showRestrictedAreas';
    this.setState({
      [name]: val,
      loaded: true,
      showMap: true,
    });
  };

  handleZoneChange = async (selectedZone, callType) => {
    this.setState({ selectedZone });
    if (callType !== 'zone_state') {
      //This is being called because a zone is saved as a state var, not because of a change in the dropdown select
      await this.props.actions.setSelectedZone(selectedZone.id);
    }
    this.setState({
      showDateSelect: true,
      showRideSegmentFilters: true,
      loaded: true,
    });
  };

  handleChangeShowStart = (event) => {
    const {
      target: { checked },
    } = event;
    this.setState({
      show_start: checked,
      show_end: checked,
    });
  };

  rideClick = async (ride, lat, lng, newMapCenter, newZoom) => {
    const location = {
      lat: lat,
      lng: lng,
    };
    this.setState({
      selectedInvoiceRide: ride,
      selectedRideClickLocation: location,
      mapCenter: newMapCenter,
      mapZoom: newZoom,
      visible: true,
    });
  };

  rideIdClick = (ride) => {
    this.props.history.push({
      pathname: '/ride',
      data: { selectedRide: ride },
    });
  };

  scooterClick = (scooter) => {
    const vehicle = {
      vehicle: {
        name: scooter,
      },
    };
    this.props.history.push({
      pathname: '/vehicles/edit',
      data: { selectedScooter: vehicle },
    });
  };

  handleShowParkingChange = async (event) => {
    this.setState({
      showParkingSpots: event.target.checked,
    });
  };

  submitRideMap = () => {
    if (
      this.state.show_start === false &&
      this.state.show_middle === false &&
      this.state.show_end === false
    ) {
      alert('Select one of the ride segments to generate Ride Map');
    } else {
      this.setState({
        loaded: false,
        mapPlaces: [],
        mapStartPlaces: [],
        mapRideRoutes: [],
        mapEndPlaces: [],
      });
      const selectedZoneId = this.state.selectedZone.id;
      const start = moment(this.state.rides_start)
        .tz('America/Los_Angeles')
        .format('MMM DD YYYY HH:mm:ss');
      const end = moment(this.state.rides_end)
        .tz('America/Los_Angeles')
        .format('MMM DD YYYY HH:mm:ss');
      const params = {
        start: start,
        end: end,
        includeRideDetails: true,
      };
      const start_date = moment(this.state.rides_start, 'DD/MM/YYYY');
      const end_date = moment(this.state.rides_end, 'DD/MM/YYYY');
      const daysDiff = end_date.diff(start_date, 'days');
      if (daysDiff > 700) {
        alert(
          'Maximum date range is 1 week. You selected ' +
            daysDiff +
            ' days. Try a shorter date range'
        );
        this.setState({
          loaded: true,
        });
      } else {
        let zone_boundary_points = [];
        if (this.props.zones.length > 0) {
          this.props.zones.forEach((zone) => {
            if (zone.id === selectedZoneId) {
              zone.geofence.forEach((points) => {
                const coords = {
                  lat: points[0],
                  lng: points[1],
                };
                zone_boundary_points.push(coords);
              });
            }
          });
        }
        this.setState({
          mapZoneBoundary: zone_boundary_points,
        });
        this.props.actions.getFeatures(selectedZoneId);
        this.props.actions.getRidesInZone(selectedZoneId, params).then(() => {
          this.buildMap(this.state.selected_ride_filter);
        });
      }
    }
  };

  handleStatusSegmentChange = async (val) => {
    this.buildMap(val);
  };

  buildMap = async (val) => {
    let routesStarts = [];
    let routesEnds = [];
    let routes = [];
    let rides = [];
    let includeRide = false;
    const rideRoutes = this.props.zone_rides.routes;
    await Promise.all(
      rideRoutes.map((ride) => {
        if (
          val === 'all_rides' ||
          (val === 'short_rides' && ride.duration < 60) ||
          (val === 'free_rides' && ride.amount === 0) ||
          (val === 'walkaways' && ride.ride_end_reason === 'walkaway_detected')
        ) {
          includeRide = true;
        } else {
          includeRide = false;
        }
        if (includeRide) {
          rides.push(ride);
          let x = 0;
          let lat = '';
          let lng = '';
          let routeStarts = [];
          let route = [];
          let routeEnds = [];
          let previous_lat = '';
          let previous_lng = '';
          const rides_length = ride.route.features.length;
          ride.route.features.forEach((point, i) => {
            lat = point.geometry.coordinates[1];
            lng = point.geometry.coordinates[0];
            const route_location = { lat: lat, lng: lng };
            if (i === 0) {
              this.setState({
                mapCenter: route_location,
              });
            }
            if (this.state.show_start === true) {
              if (x === 0) {
                routeStarts.push(route_location);
              }
            }
            if (this.state.show_middle === true) {
              if (x < rides_length - 1) {
                if (previous_lat !== route_location.lat && previous_lng !== route_location.lng) {
                  route.push(route_location);
                }
              }
            }
            if (this.state.show_end === true) {
              if (x === rides_length - 1) {
                routeEnds.push(route_location);
              }
            }
            previous_lat = route_location.lat;
            previous_lng = route_location.lng;
            x++;
          });
          routes.push(route);

          if (this.state.show_end === true) {
            routesEnds.push(routeEnds);
          }
          if (this.state.show_start === true) {
            routesStarts.push(routeStarts);
          }
        }
        return null;
      })
    );
    this.setState({
      mapRideRoutes: routes,
      mapRides: rides,
      mapStartPlaces: routesStarts,
      mapEndPlaces: routesEnds,
      loaded: true,
      showRideMap: true,
      selected_ride_filter: val,
    });
  };

  loadPopup = () => {
    return (
      <Popup
        open={this.state.open}
        closeOnDocumentClick
        onClose={this.closePopup}
        overlayStyle={{ backgroundColor: 'light-grey' }}
        contentStyle={{ maxHeight: '100vh', overflowY: 'auto' }}
      />
    );
  };

  render() {
    let title = '';
    if (this.state.selectedInvoiceRide) {
      title = 'Ride Details: ' + this.state.selectedInvoiceRide.user.phone;
    }
    const modalTitle = <ModalTitle title={title} />;
    const popup = this.loadPopup;
    const {
      selectedZone,
      showZoneSelect,
      showDateSelect,
      showRideSegmentFilters,
      show_start,
      show_end,
      showRideMap,
    } = this.state;
    let showStarts = this.state.mapStartPlaces;
    let showEnds = this.state.mapEndPlaces;
    if (!show_start) {
      showStarts = [];
    }
    if (!show_end) {
      showEnds = [];
    }
    return (
      <AuthenticatedPage className="edit_vehicle_container">
        <div className="page-title">Ride Map Generator</div>
        {showZoneSelect && (
          <Fragment>
            <h3>Select Zone:</h3>
            <DropdownList
              className="vehicles_container__select"
              data={this.state.activeZones}
              value={selectedZone}
              valueField="id"
              textField="name"
              onChange={this.handleZoneChange}
              placeholder="Select a Zone"
            />
          </Fragment>
        )}
        {showDateSelect && (
          <Fragment>
            <div className="date_selector_container">
              <h3>Select Ride Map date range ( 7 day max )</h3>
            </div>
            <div className="date_select_input_section">
              <div className="date_selector_label">
                start:
                <DateTimePicker
                  className="add_pricing_plan_form__data_input"
                  defaultValue={this.state.rides_start}
                  onChange={(value) => this.setState({ rides_start: value })}
                />
              </div>
              <div className="date_selector_label">
                end:
                <DateTimePicker
                  className="add_pricing_plan_form__data_input"
                  defaultValue={this.state.rides_end}
                  onChange={(value) => this.setState({ rides_end: value })}
                />
              </div>
            </div>
          </Fragment>
        )}
        {showRideSegmentFilters && (
          <input
            type="button"
            className="form_btn"
            value="Submit Ride Map"
            onClick={this.submitRideMap}
          />
        )}
        {showRideMap ? (
          this.props.zone_rides.routes.length > 0 ? (
            <Fragment>
              <div className="ride_filters_container">
                <div className="ride_filter_segment_container">
                  <DropdownList
                    className="vehicles_container__select select-zone"
                    data={[
                      { label: 'All Rides', value: 'all_rides' },
                      { label: 'Short Rides', value: 'short_rides' },
                      { label: 'Free Rides', value: 'free_rides' },
                      { label: 'Walkaways', value: 'walkaways' },
                    ]}
                    value={this.state.selected_ride_filter}
                    valueField="value"
                    textField="label"
                    onChange={({ value }) => this.handleStatusSegmentChange(value)}
                    placeholder="Select Rides to show"
                  />
                </div>
                <div className="show_toggle">
                  <Toggle
                    id="showStartsToggle"
                    defaultChecked={show_start}
                    aria-labelledby="biscuit-label"
                    onChange={this.handleChangeShowStart}
                  />
                  <label className="form_input_section__label" htmlFor="show_start">
                    Ride Starts and Ends
                  </label>
                </div>
                <div className="show_toggle">
                  <Toggle
                    id="parking_toggle"
                    defaultChecked={this.state.showRestrictedAreas}
                    aria-labelledby="biscuit-label"
                    onChange={this.handleShowRestrictedAreasChange}
                  />
                  <span id="parking-label" className="form_input_section__label">
                    Geofences
                  </span>
                </div>
                <div className="show_toggle">
                  <Toggle
                    id="parking_toggle"
                    defaultChecked={this.state.showParkingSpots}
                    aria-labelledby="biscuit-label"
                    onChange={this.handleShowParkingChange}
                  />
                  <span id="parking-label" className="form_input_section__label">
                    Parking Spots
                  </span>
                </div>
              </div>
              <Map
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '600px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                center={this.state.mapCenter}
                zoom={12}
                routePlaces={this.state.mapRideRoutes}
                routeStarts={showStarts}
                routeEnds={showEnds}
                restrictedAreas={this.props.features.restrictedAreas}
                parkingSpots={this.props.features.parkingSpots}
                showPolygons={this.state.showRestrictedAreas}
                showParkingSpots={this.state.showParkingSpots}
                mapZoneBoundary={this.state.mapZoneBoundary}
                rides={this.state.mapRides}
                rideClick={this.rideClick}
                selectedRide={this.state.selectedRide}
                infoWindowClick={this.infoWindowClick}
                openMapClick={this.openMapClick}
                selectedRideClickLocation={this.state.selectedRideClickLocation}
              />
            </Fragment>
          ) : (
            <p>No rides for the selected date range.</p>
          )
        ) : (
          <p></p>
        )}
        <Modal
          title={modalTitle}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <div className="modal">
            <RideDetails
              ride={this.state.selectedInvoiceRide}
              onSubmitPartialRefund={this.onSubmitPartialRefund}
              restrictRefund="false"
              showPartialRefund={this.state.showPartialRefund}
            />
          </div>
        </Modal>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closePopup}
          overlayStyle={{ backgroundColor: 'light-grey' }}
          contentStyle={{ maxHeight: '100vh', overflowY: 'auto' }}>
          {popup}
        </Popup>
        <Loader loaded={this.state.loaded} />
      </AuthenticatedPage>
    );
  }
}

RideMaps.propTypes = {
  actions: PropTypes.object,
  admin: PropTypes.object,
  features: PropTypes.object,
  history: PropTypes.object,
  selected_zone: PropTypes.number,
  zone_rides: PropTypes.object,
  zones: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    admin,
    zones,
    users,
    vehicles: { vehicles },
    zone_rides,
    rides,
    selected_zone,
    user,
  } = state;
  const restrictedAccess = admin && admin.restricted_access;
  return {
    admin,
    zones: zones.zones,
    features: zones.features,
    restrictedAccess,
    users,
    user,
    vehicles,
    zone_rides,
    rides,
    selected_zone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getActiveZones,
        getFeatures,
        getRidesInZone,
        setSelectedZone,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RideMaps);
